import React, { Component } from "react";
import {Row, Col, Container} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import "../styles/Intro.css";

export default class Intro extends Component {
    render() {
        // const introImageOptions = '?fm=png&q=100'
        const introImageOptions = '?fm=png&q=90'
        const introImageUrl = 'https://images.ctfassets.net/16bdn4f7q74v/61ltnmJsrnHDXeEj946oXd/bd4a0f64d2d4c59f28ee348bb966edbd/Intro.png'
        const introImage = introImageUrl + introImageOptions
        return (
            <section id='Intro'>
                <Container>
                    <Row className="my-4 bg-dark">
                        <Col md="6" className="pl-4 pt-4 bg-light">
                            <p className="mb-1">Our story,</p>
                            <h2 className="mb-1">Pauline Murage and Zuri.</h2>
                            <FontAwesomeIcon icon={'star'} size="2x" color="white"/>
                            <FontAwesomeIcon icon={'star'} size="2x" color="white"/>
                            <FontAwesomeIcon icon={'star'} size="2x" color="white"/>
                            <FontAwesomeIcon icon={'star'} size="2x" color="white"/>
                            <FontAwesomeIcon icon={'star'} size="2x" color="white"/>
                            <p className="my-3 text-dark">Pauline has always had a passion for beauty and exceeding client expectations, however the route to opening the doors at Zuri was not straight forward.</p>
                            <p className="my-3 text-dark">Her beauty journey started in Kenya, where local interest with global treatment trends meant there was a growing demand for beauty. In Kenya relationships and client reputation are key to any business, customers have high expectations and competition is fierce.</p>
                            <p className="my-3 text-dark">Later after moving to the Caribbean, Pauline delivered treatments to prominent figures within the island community and established strong client support through excellence of service. </p>
                            <p className="my-3 text-dark">After again relocating, this time to the UK Pauline strengthened her abilities further, returning to education and graduated with a diploma and award as CIDESCO Student of the year. Pauline then spent time working in Edinburgh area spas before deciding the time was right to open the doors at Zuri.</p>
                            <p className="my-3 text-dark">For those that may be wondering, Zuri is Swahili word for Beautiful, Pretty or Gorgeous. Swahili being one of the 3 languages Pauline would happily have a chat to you in.</p>
                        </Col>
                        <Col md="6" className="bg-light">
                            <img className="img-fluid d-block mx-auto" src={introImage}
                                alt="41 Dalry Road Edinburgh, EH11 2BU"></img>
                            {/* <img className="img-fluid d-block mx-auto" src="images/Intro.png"
                            alt="41 Dalry Road Edinburgh, EH11 2BU"></img> */}
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}