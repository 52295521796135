import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Row, Col, Container, Button, ButtonGroup} from 'reactstrap';

import "../styles/Cover.css";

export default class Cover extends Component {
  render() {
    // const coverImageOptions = '?fm=png&q=90&w=1920&h=500'
    const coverImageOptions = '?fm=png&q=90&w=1920&h=500'
    const coverImageUrl = 'https://images.ctfassets.net/16bdn4f7q74v/6NH7lEHQppGQBB45sndi92/ca3f4d7e602b1eaf77d0a89bc375a288/Flower-Towel.jpg'
    const coverImage = 'url(' + coverImageUrl + coverImageOptions + ')'
    return (
        // <section id='Cover' className="photo-overlay d-flex align-items-md-center" style={{ backgroundImage: 'url(images/Cover.jpg)'}}>
        <section id='Cover' className="photo-overlay d-flex align-items-md-center" style={{ backgroundImage: coverImage}}>
            <Container>
                    <Row>
                        <Col md='7' className="pb-4 align-self-center text-center" style={{ transform: 'scale(1.8)'}}>
                            <h1>Zuri Beauty</h1>
                            <p className="text-secondary">Beauty Made Simple</p>
                        </Col>
                        <Col md='4' className="p-4 bg-dark-opaque text-center">
                            <ButtonGroup vertical>
                                <h4 className="mb-4">Start your Experiance</h4>
                                <Button outline color="primary" className="mt-2" tag={Link} to="/treatments" >
                                    Book Now
                                </Button>
                                <Button outline color="primary" className="mt-2" tag={Link} to="/reviews" >
                                    Client Reviews
                                </Button>
                            </ButtonGroup>
                        </Col>
                    </Row>
            </Container>
        </section>
    );
  }
}