import React from "react";
import loadable from '@loadable/component'
import { Route, Switch, Redirect } from "react-router-dom";

import AppliedRoute from './components/AppliedRoute';


import Home from "./containers/Home";

const Reviews = loadable(() => import( /* webpackChunkName: "reviews" */  './containers/Reviews'));
const Therapists = loadable(() => import( /* webpackChunkName: "therapists" */  './containers/Therapists'));
const Treatment = loadable(() => import( /* webpackChunkName: "treatment" */  './containers/Treatment'));
const Treatments = loadable(() => import( /* webpackChunkName: "treatments" */  './containers/Treatments'));
const Salon = loadable(() => import( /* webpackChunkName: "salon" */  './containers/Salon'));
const NotFound = loadable(() => import( /* webpackChunkName: "notfound" */  './containers/NotFound'));
const Booking = loadable(() => import( /* webpackChunkName: "booking" */  './containers/Booking'));
const Article = loadable(() => import( /* webpackChunkName: "Article" */  './containers/Article'));
const Articles = loadable(() => import( /* webpackChunkName: "Articles" */  './containers/Articles'));


// const componentRegistry = {
//   "Home": Home,
//   "Reviews": Reviews,
//   "Therapists": Therapists,
//   "Treatment": Treatment,
//   "Treatments": Treatment,
//   "Salon": Salon,
//   "Test": Test
// }

// const log = (message) => {
//   console.log('Routes: ', message)
// }

const Routes = (props) => (
  <Switch>

    { /* Redirects */}
    <Redirect from="/reviews.html" to="/reviews" />
    <Redirect from="/therapists.html" to="/therapists" />
    <Redirect from="/treatments/nails" to="/treatments/hands-and-feet" />
    <Redirect from="/treatments/shellac.html" to="/treatments/hands-and-feet/shellac" />
    <Redirect from="/treatments/facials.html" to="/treatments/facials" />
    <Redirect from="/treatments/hdbrows.html" to="/treatments/eye-treatments/hd-brows" />

    { /* Static Routes */}
    <Route path="/" exact component={Home} />
    <Route path="/reviews" exact component={Reviews} />
    <AppliedRoute path="/booking" exact component={Booking} props={props} />

    { /* Dynamic Routes for promotions */}
    <AppliedRoute path="/promotions" exact component={Treatment} props={props} />

    { /* Routes With Contentful Data */}
    <AppliedRoute path="/our-salon" exact component={Salon} props={props} />
    <AppliedRoute path="/therapists" exact component={Therapists} props={props} />

    { /* Dynamic Routes for treatments */}
    <AppliedRoute path="/treatments" exact component={Treatments} props={props} />
    <AppliedRoute path="/treatments/:catagory" component={Treatment} props={props} />

    { /* Dynamic Routes for blog posts */}
    <AppliedRoute path="/blog" exact component={Articles} props={props} />
    <AppliedRoute path="/blog/:article" component={Article} props={props} />

    { /* Test Route */}
    {/* <AppliedRoute path="/test" exact component={Test} props={props} /> */}


    { /* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
)

export default Routes;