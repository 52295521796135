import React, { Component } from "react";
import { Helmet } from "react-helmet";

import Cover from "../components/Cover";
import Intro from "../components/Intro";
import ImageGrid from "../components/ImageGrid";

import {structuredDataOrganization} from "../seo/Markup"

export default class Home extends Component {
  constructor(props) {
    super(props)
    this.meta = {}
    this.meta.canonical = 'https://zuri.co.uk'
    this.meta.description = "Haymarket's best Beauty Salon. At Zuri, we offer a wide range of beauty treatments including branded treatments like HD Brows, Shellac Nails, and Lycon Waxing. We also offer the latest Laser Hair Removal technology. Located on Dalry road Edinburgh just behind Haymarket Station."
    // console.log('Home',props)
  }
  render() {
    // console.log('Home',this.props)
    return (
      <main>
        <Helmet>
          <title>Home</title>
          <meta name="description" content={this.meta.description} />
          <link rel="canonical" href={this.meta.canonical}></link>
          {/* Structured data */}
          <script type="application/ld+json">
            {structuredDataOrganization}
          </script>
        </Helmet>
        <Cover/>
        <ImageGrid/>
        <Intro/>
      </main>
    );
  }
}