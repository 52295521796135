import React, { Component } from "react";
import Routes from "./Routes";

import ReactGA from 'react-ga';

import { cfClient, getContentData } from "./apis/content";
import { sbClient, getTreatmentData } from "./apis/simplybookme";

import { Helmet } from "react-helmet";
import { Container } from 'reactstrap';

import Navigation from "./components/Navigation";
import Footer from "./components/Footer";

import PushMenu from 'react-push-menu';
import { getMenu } from "./utils/data";
// import {FaBars, FaChevronRight, FaChevronLeft} from 'react-icons/fa';
import './styles/menu.css';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faStar, faCaretSquareDown, faCaretDown, faBars, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Link } from "react-router-dom"
import { NavLink } from 'reactstrap';

library.add(fab, faStar, faCaretSquareDown, faCaretDown, faBars, faChevronRight, faChevronLeft)

ReactGA.initialize('UA-9907768-3');
ReactGA.pageview(window.location.pathname + window.location.search);

class LinkComponent extends Component {
  render() {
    const { propMap, node } = this.props.data;
    const nodeTitle = node[propMap.displayName];
    return (
      <NavLink tag={Link} to={node[propMap.url] || "#"}
        onClick={(e) => {
          this.props.onNodeClick(e, this.props.data);
        }}
        className={`rpm-node-link rpm-inline-block ${node[propMap.linkClasses] || ''}`}>
        {nodeTitle}
      </NavLink>
    );
  }
}


class App extends Component {
  constructor(props) {
    super(props);
    this.buildMenu = this.buildMenu.bind(this);

    this.toggle = this.toggle.bind(this);
    this.state = {
      contentData: null,
      treatmentData: null,
      menuData: null,
      isOpen: false,
    };
  }

  buildMenu() {
    this.setState({
      menuData: getMenu(this.state.contentData[0].fields.navLinks)
    });
  }

  componentDidMount() {

    getContentData(cfClient(), 'navigation', 3)
      .then(contentData => (this.setState({ contentData: contentData }))
      )

    sbClient()
      .then(client => getTreatmentData(client))
      .then(treatmentData => (this.setState({ treatmentData: treatmentData }))
      )

    if (this.state.contentData && !this.state.menuData) {
      this.buildMenu('mount')
    }
  }

  componentDidUpdate() {
    if (this.state.contentData && !this.state.menuData) {
      this.buildMenu('update')
    }
  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const contentData = this.state.contentData
    const treatmentData = this.state.treatmentData
    const menuData = this.state.menuData

    return (
      <Container fluid id='App' className="p-0">
        <Helmet defaultTitle="Zuri Beauty" titleTemplate="Zuri Beauty - %s">
          <meta charset="utf-8" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="theme-color" content="#4A99BF" />
          <link rel="manifest" href="/manifest.json" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" href="/favicon-32x32.png" sizes="32x32" />
          <link rel="icon" type="image/png" href="/favicon-16x16.png" sizes="16x16" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="geo.region" content="GB-EDH" />
          <meta name="geo.placename" content="Edinburgh" />
          <meta name="geo.position" content="55.944754;-3.21766" />
          <meta name="ICBM" content="55.944754, -3.21766" />
          <meta name="author" content="Zuri Team"></meta>
          <link rel="canonical" href="https://zuri.co.uk"></link>
        </Helmet>
        {contentData && <Navigation contentData={contentData} onClick={this.toggle} isOpen={this.state.isOpen} />}
        {/* {contentData && <Routes contentData={contentData} treatmentData={treatmentData}/>} */}

        {menuData &&
          <PushMenu
            backComponent={() => (<FontAwesomeIcon icon={['fa', 'chevron-left']} size="1x" style={{ margin: '14px' }} />)}
            expanderComponent={() => (<FontAwesomeIcon icon={['fa', 'chevron-right']} size="1x" />)}
            onNodeClick={(e, data) => {
              // console.log('onNodeClick:', data) // => {menu, node, propMap, rootNode}
              data.menu.tools.reset()
            }}
            linkComponent={LinkComponent}
            isOpen={false}
            autoHide={true}
            nodes={this.state.menuData}
            type={'cover'}
            propMap={{
              url: 'link',
              displayName: 'name',
              childPropName: 'children'
            }}
          >
            {/* <Navigation contentData={contentData} onClick={this.toggle} isOpen={this.state.isOpen} /> */}
            <div className="rpm-trigger">
              <Routes contentData={contentData} treatmentData={treatmentData} />
            </div>
          </PushMenu>}
        <Footer />
      </Container>
    );
  }
}

export default App;