import React from "react";
import { Link } from "react-router-dom";
import {
    Navbar, NavbarBrand
} from 'reactstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import slugify from 'react-slugify';

// const DropDownOptions = (props) => {
//     const heading = props.heading
//     const dropDownLinkData = props.dropDownData
//     const path = props.path

//     return (
//         <UncontrolledDropdown nav inNavbar>
//             <DropdownToggle nav caret>
//                 {heading}
//             </DropdownToggle>
//             <DropdownMenu right>
//                 {dropDownLinkData.map((link, i) => (
//                     <DropdownItem>
//                         <NavLink key={i} tag={Link} to={path + '/' + slugify(heading) + '/' + slugify(link.fields.heading)}>{link.fields.heading}</NavLink>
//                     </DropdownItem>
//                 ))}
//             </DropdownMenu>
//         </UncontrolledDropdown>
            
//     )
// }

// const MainNavBarOptions = (props) => {
//     // console.log('MainNavBarOptions: ', props)
//     // console.log('MainNavBarOptions: ', props.contentData[0].fields.navLinks)
    
//     return props.contentData[0].fields.navLinks.map((link, i) => (link.fields.navLinks
//         ? <DropdownItem><DropDownOptions key={i} heading={link.fields.heading} path={''} dropDownData={link.fields.navLinks} /></DropdownItem>
//         : <DropdownItem>
//             <NavLink key={i} tag={Link} to={'/' + slugify(link.fields.heading)}>{link.fields.heading}</NavLink>
//         </DropdownItem>
//     ))
// }

const Navigation = props => (
    <Navbar dark color="dark" expand="md" className="px-5">
        <FontAwesomeIcon style={{marginRight: '30px'}} className="rpm-trigger text-primary" id="rpm-trigger" icon={['fa', 'bars']} size="3x"/>
        <NavbarBrand tag={Link} to="/" className="text-secondary">Zuri Beauty</NavbarBrand>
        {/* <NavbarToggler onClick={props.onClick} />
        <Collapse isOpen={props.isOpen} navbar>
            <Nav className="ml-auto" navbar>
                <MainNavBarOptions contentData={props.contentData} />
            </Nav>
        </Collapse> */}
    </Navbar>
)
export default Navigation;