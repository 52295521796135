import React from "react";
import { Route } from "react-router-dom";

export default class AppliedRoute extends React.Component {
  render() {
    // console.log('AppliedRoute: ', this.props)
    const { component: C, props: cProps, ...rest } = this.props;
    return (
      <Route key={this.props.location.pathname} {...rest} render={(routeData,props) => <C {...props} {...cProps} routeData={routeData}/>} />
    );
  }
}

