  const structuredDataOrganization = `{
    "@context": "http://schema.org",
    "@type": "BeautySalon",
    "@id":"https://zuri.co.uk",
    "url":"https://zuri.co.uk",
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "41 Dalry Road",
        "addressLocality": "Edinburgh",
        "addressRegion": "City of Edinburgh",
        "postalCode": "EH11 2BU",
        "addressCountry": "GB"
      },
    "latitude":"55.944754",
    "longitude":"-3.217659",
    "name":"Zuri",
    "image":"https://images.ctfassets.net/16bdn4f7q74v/61ltnmJsrnHDXeEj946oXd/bd4a0f64d2d4c59f28ee348bb966edbd/Intro.png?fm=png&q=90",
    "logo": "https://zuri.co.uk/zuri-bw-tb.png",
    "priceRange":"£5 - £100",
    "email":"mailto:appointments@zuri.co.uk",
    "telephone":"0131 346 8888",
    "sameAs" : [ 
        "https://www.facebook.com/ZuriBeautyTherapy",
        "https://www.instagram.com/zuribeautytherapy/"
    ],
    "hasMap": "https://goo.gl/maps/M1zpaC3rhYt"
  }`

  export {
    structuredDataOrganization
  }

