import slugify from 'react-slugify';

// const log = (message) => {
//   console.log('Data: ', message)
// }

const contentDataRoot = (contentData, field, fieldValue, depth) => {
  var cData = null
  // console.log('Data: Target: ', depth, contentData, field, fieldValue)

  for (const x of Array.from(contentData)) {
    if (slugify(x.fields[field]) === fieldValue) {
      // console.log('Data: ', fieldValue)
      cData = x.fields
      if ('navLinks' in x.fields) {
        cData.children = x.fields.navLinks.map((links) => (links.fields.heading))
      }
    } else if ('navLinks' in x.fields) {
      cData = contentDataRoot(x.fields.navLinks, field, fieldValue, depth + 1)
      if ((cData != null) && !cData.siblings) { cData.siblings = x.fields.navLinks.map((links) => (links.fields.heading)) }
    }
    if (cData) { break }
  }
  return cData
}

const getCatagories = (contentData, heading, id, image) => {
  return {
    name: heading,
    image: image,
    children: contentData.map((links) => {
      const bannerImage = links.fields.bannerImage ? links.fields.bannerImage.fields.file.url : null

      return (links.fields.navLinks
        ? getCatagories(links.fields.navLinks, links.fields.heading, links.fields.id, bannerImage)
        : {
          id: links.fields.bookingCatagory,
          name: links.fields.heading,
          image: links.fields.bannerImage.fields.file.url
        }
      )
    })
  }
}

const getMenu = (contentData) => {
  return {
    header: 'All Categories',
    children: getMenuTree(contentData, '')
  }
}

const getMenuTree = (contentData, path) => {
  let data = {}
  data = contentData.map((links, index) => {
    const slugLink = slugify(links.fields.heading)
    return (links.fields.navLinks
      ? {
        children: getMenuTree(links.fields.navLinks, `${path}/${slugLink}`),
        name: links.fields.heading,
        link: `${path}/${slugLink}`,
        id: index
      }
      : {
        name: links.fields.heading,
        link: `${path}/${slugLink}`,
        id: index
      })
  })
  return data
}


const getData = (props) => {
  var data = {}

  data.currentPath = props.routeData.location.pathname
  data.currentPathData = data.currentPath.split('/')
  data.self = data.currentPathData[data.currentPathData.length - 1]
  data.parent = data.currentPathData[data.currentPathData.length - 2]
  data.contentExpanded = false

  data = { ...data, ...contentDataRoot(props.contentData, 'heading', data.self, 0) }

  data.catagories = data.navLinks
    ? getCatagories(data.navLinks, data.heading, data.id)
    : [{
      id: data.bookingCatagory,
      name: data.heading
    }]


  // log(data)
  return data
}

const filterTreatmentData = (obj, filter, filterValue) => {
  // console.log('filterTreatmentData', obj, filter, filterValue)
  const filteredTreatmentData = (Object.keys(obj).reduce((acc, val) => (
    (obj[val][filter].includes(filterValue)
      ? { ...acc, [val]: obj[val] }
      : acc
    )), {})
  )
  return filteredTreatmentData
}

const filterArticleData = (obj, filter, filterValue) => {
  // console.log('filterTreatmentData', obj, filter, filterValue)
  const filteredArticleData = (Object.keys(obj).reduce((acc, val) => (
    (obj[val][filter].includes(filterValue)
      ? { ...acc, [val]: obj[val] }
      : acc
    )), {})
  )
  return filteredArticleData
}

const getTreatmentData = (treatmentData, catagories) => {
  // console.log('getTreatmentData', treatmentData, catagories)
  // var treatmentData = catagories.map((catagory) => (filterTreatmentData(treatmentData, 'categories', catagory.id)))[0]
  // console.log('getTreatmentData', treatmentData)
  return treatmentData
}

const getArticleData = (articleData, catagories) => {
  return articleData
}



export {
  getData, getTreatmentData, filterTreatmentData, getCatagories, getMenu, getArticleData, filterArticleData
}