import * as contentful from "contentful";


const cfClient = () => (contentful.createClient({
  space: '16bdn4f7q74v',
  accessToken: 'b44644d009156aa075231bf2c16098e86e8c0df0230c76bfc3ff0d40011cd509'
}))


const getContentData = (cfClient, type, depth) => {
  const content =  cfClient.getEntries({
    content_type: type,
    include: depth
  }).then(response => response.items)
    .catch(err => console.error(err));
  // console.log('content',content)
  return content
};

export {
  cfClient, getContentData
}