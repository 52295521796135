import React from "react";
import {Container,Row, Col} from 'reactstrap';
import { Link } from "react-router-dom";

const imageGridStyle = {
    width: '100%',
    height:"100%",
    objectFit: 'cover',
    objectPosition: 'center',
    background: 'black',
    opacity: '0.5',
}

const gridStyle = {
    height: '200px',
    objectFit: 'none',
    objectPosition: 'center',
    // background: 'black',
    // opacity: '0.5',
}

const imageCaptionStyle = {
    top: '50%',
    left: '50%',
    textAlign: 'center',
    transform: 'translate(-50%, -50%) scale(1.5)'
}

const ImageGrid = props => {
    // const srcImage = props.bannerImage + '?fm=png&q=90&w=1920&h=500';
    // const srcImage = props.bannerImage + '?fm=png&q=90&w=1920&h=500';
    return(
        <section id='Banner'>
            <Container fluid className='position-relative p-0'>
                <Row className="d-flex flex-row flex-wrap justify-content-center">
                    <Col md="3" className='p-0' style={gridStyle}>
                        <Link to="/treatments/eye-treatments/hd-brows">
                            <img src="//images.ctfassets.net/16bdn4f7q74v/6NF9wVjABJC6M5d40og8jT/cdc89ca23954ba21ff648248fb4c3336/Treatment-HD-Brows-Cover-1.jpg?fm=png&q=90&h=200" class="img-fluid img-responsive" style={imageGridStyle}/>
                            <h2 className='position-absolute p-0' style={imageCaptionStyle}>HD Brows</h2>
                        </Link>
                    </Col>
                    <Col md="3" className='p-0' style={gridStyle}>
                        <Link to="/treatments/waxing/female-lycon-waxing">
                            <img src="//images.ctfassets.net/16bdn4f7q74v/i20OpJXTxbvl3OZ5HPevr/1e95f02cedd841f02a89f36c8347a2c6/Wax-6.jpg?fm=png&q=90&h=200" class="img-fluid img-responsive" style={imageGridStyle}/>
                            <h2 className='position-absolute p-0' style={imageCaptionStyle}>Lycon Wax</h2>
                        </Link>
                    </Col>
                    <Col md="3" className='p-0' style={gridStyle}>
                        <Link to="/treatments/laser-hair-removal">
                            <img src="//images.ctfassets.net/16bdn4f7q74v/7yRcEhzDEEmtKJxD1MoT7n/3027a5bb0d402511ce6204b6379426a7/treatment-laser-1.jpg?fm=png&q=90&h=200" class="img-fluid img-responsive" style={imageGridStyle}/>
                            <h2 className='position-absolute p-0' style={imageCaptionStyle}>Laser Hair Removal</h2>
                        </Link>
                    </Col>
                    <Col md="3" className='p-0' style={gridStyle}>
                        <Link to="/treatments/hands-and-feet/shellac">
                            <img src="//images.ctfassets.net/16bdn4f7q74v/1TCar0JWn3M0LXWorOzXDs/384d39ed5b85a744fd0128ab7e963601/Product-CND-2.jpg?fm=png&q=90&h=200" class="img-fluid img-responsive" style={imageGridStyle}/>
                            <h2 className='position-absolute p-0' style={imageCaptionStyle}>Shellac Nails</h2>
                        </Link>
                    </Col>
                    </Row>
            </Container>
        </section>
    )
}
export default ImageGrid;