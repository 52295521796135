import JsonRpcClient from './utils/jsonrpcclient'

// const log = (message) => {
//     console.log('sb: ', message)
// }

const sbGetToken = () => {
    const api = new JsonRpcClient({
        endpoint: 'https://user-api.simplybook.me/login',
    });
    return api
        .request('getToken',
            'zuri',
            '608db6549c6a79665cbdd74c0b0b18a2e21f62c6be61aaded1192f3888b40e19'
        ).then(response => response)
        .catch(err => console.error(err))
};

const sbClient = () => {
    return sbGetToken()
        .then(token => {
            const api = new JsonRpcClient({
                endpoint: 'https://user-api.simplybook.me',
                headers: {
                    'X-Company-Login': 'zuri',
                    'X-Token': token
                }
            })
            return api
        })
}

const getTreatmentData = (sbClient) => (sbClient.request('getEventList'))

export {
    sbGetToken, sbClient, getTreatmentData
}