import React, { Component } from "react";
import {Row, Col, Container} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// import "../styles/Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer className="bg-dark text-center">
          <Container fluid>
                <Row className="py-3">
                    {/* <Col md='4' className="pb-2">
                        <h2 className="text-muted border-bottom">Contact</h2>
                        <p className="mt-4 mb-3" style={{ transform: 'scale(1.8)'}}>
                        <p className="mt-4 mb-3">
                            <a href="tel:+44 131 346 8888" className="text-secondary">0131 346 8888</a>
                        </p>
                    </Col>
                    <Col md='4' className="pb-2 text-secondary">
                        TEST
                    </Col>
                    <Col md='4' className="pb-2 text-secondary">
                        TEST
                    </Col> */}
                    <Col md='4' className="pb-2">
                        <h2 className="text-muted border-bottom">Contact</h2>
                        <p className="mt-4 mb-3">
                            <a href="tel:+44 131 346 8888" className="text-secondary display-3">0131 346 8888</a>
                        </p>
                        <p className="mt-0 mb-2">
                            <a href="mailto:appointments@zuri.co.uk" className="text-secondary display-3">appointments@zuri.co.uk</a>
                        </p>
                    </Col>
                    <Col md='4' className="pb-2">
                        <h2 className="text-muted border-bottom">Social</h2>
                        <Row className="pt-1" >
                            <Col xs='4'>
                                <a href="https://www.facebook.com/ZuriBeautyTherapy" aria-label="Facebook" className="text-secondary">
                                    <FontAwesomeIcon icon={['fab', 'facebook-f']} size="3x"/>
                                </a>
                            </Col>
                            <Col xs='4'>
                                <a href="https://goo.gl/maps/M1zpaC3rhYt" aria-label="Google" className="text-secondary">
                                    <FontAwesomeIcon icon={['fab', 'google']} size="3x"/>
                                </a>
                            </Col>
                            <Col xs='4'>
                                <a href="https://www.instagram.com/zuribeautytherapy/" aria-label="Instagram" className="text-secondary">
                                    <FontAwesomeIcon icon={['fab', 'instagram']} size="3x"/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                    <Col md='4'>
                        <h2 className="text-muted border-bottom">Location</h2>
                        <p className="mb-0 text-secondary  display-3">
                            41 Dalry Road<br></br>
                            Edinburgh, EH11 2BU<br></br>
                            <a href="https://goo.gl/maps/LiT4AinkFd72" className="text-muted">
                                Map-Link
                            </a>
                        </p>
                    </Col>
                </Row>
          </Container>
      </footer>
    );
  }
}
export default Footer