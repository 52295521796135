import React from "react";
import ReactDOM from "react-dom";
// import { render } from 'react-snapshot';
import { BrowserRouter as Router } from "react-router-dom";
import './styles/zuri.css';
import App from "./App";
import registerServiceWorker from "./registerServiceWorker";

// render(
//   <Router>
//     <App />
//   </Router>,
//   document.getElementById("root")
// );
// registerServiceWorker();

const rootElement = document.getElementById("root");

if (rootElement.hasChildNodes()) {
  ReactDOM.hydrate(<Router>
                    <App />
                   </Router>, rootElement);
} else {
  ReactDOM.render(<Router>
                    <App />
                  </Router>, rootElement);
}